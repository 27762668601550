import { FC } from 'react';
import IconProps from './IconProps';

const SearchIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2923 19.7071C19.6828 20.0976 20.316 20.0976 20.7065 19.7071C21.097 19.3166 21.097 18.6834 20.7065 18.2929L19.2923 19.7071ZM15.7065 13.2929C15.316 12.9024 14.6828 12.9024 14.2923 13.2929C13.9018 13.6834 13.9018 14.3166 14.2923 14.7071L15.7065 13.2929ZM20.7065 18.2929L15.7065 13.2929L14.2923 14.7071L19.2923 19.7071L20.7065 18.2929ZM15.6666 9.83332C15.6666 12.5027 13.5027 14.6666 10.8333 14.6666V16.6666C14.6073 16.6666 17.6666 13.6073 17.6666 9.83332H15.6666ZM10.8333 14.6666C8.16395 14.6666 6 12.5027 6 9.83332H4C4 13.6073 7.05939 16.6666 10.8333 16.6666V14.6666ZM6 9.83332C6 7.16396 8.16396 5 10.8333 5V3C7.05939 3 4 6.05939 4 9.83332H6ZM10.8333 5C13.5027 5 15.6666 7.16395 15.6666 9.83332H17.6666C17.6666 6.05939 14.6073 3 10.8333 3V5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchIcon;
